import React, { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { EffectFade } from 'swiper/modules';
import 'swiper/css/effect-fade';
import useSwiperLogic from '../../components/useSwiperLogic';

const SushiPlayground = ({ isCurrent }) => {
  const sushiPlaygroundRef = useRef(null);
  const swiperContainerRef = useRef(null);

  // Apply Swiper logic
  useSwiperLogic('sushiPlayground', sushiPlaygroundRef, swiperContainerRef, isCurrent);

  const slides = [
    {
      src: './medias/07_sushi-nouns/sushi_2.webp',
      alt: 'Sushi Nouns DAO - Playground 1',
    },
    {
      src: './medias/07_sushi-nouns/sushi_3.webp',
      alt: 'Sushi Nouns DAO - Playground 2',
    },
    {
      src: './medias/07_sushi-nouns/sushi_4.webp',
      alt: 'Sushi Nouns DAO - Playground 3',
    },
    {
      src: './medias/07_sushi-nouns/sushi_1.webp',
      alt: 'Sushi Nouns DAO - Playground 4',
    },
  ];

  const swiperClass = slides.length > 1 ? 'swiper-pointer' : '';

  return (
    <div className="work border" id="sushiPlayground" ref={swiperContainerRef}>
      <div className={`c ${swiperClass}`}>
        <Swiper
          ref={sushiPlaygroundRef}
          loop={true}
          modules={[EffectFade]}
          effect="fade"
          spaceBetween={0}
          slidesPerView={1}
          onSwiper={(swiper) => (sushiPlaygroundRef.current = swiper)}
        >
          {slides.map((slide, index) => (
            <SwiperSlide key={index}>
              <picture>
                <img src={slide.src} alt={slide.alt} loading="lazy" />
                <div className="loader">
                  <div className="loader--animation"></div>
                </div>
              </picture>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default SushiPlayground;
