import React, { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { EffectFade } from 'swiper/modules';
import 'swiper/css/effect-fade';
import useSwiperLogic from '../../components/useSwiperLogic';

const Ipotetu = ({ isCurrent }) => {
  const ipotetuRef = useRef(null);
  const swiperContainerRef = useRef(null);

  // Apply the reusable Swiper logic hook
  useSwiperLogic('ipotetu', ipotetuRef, swiperContainerRef, isCurrent);

  const slides = [
    {
      src: './medias/ipotetu/ipotetu-img-1.webp',
      alt: 'Ipotetu Image 1',
    },
    {
      src: './medias/ipotetu/ipotetu-img-2.webp',
      alt: 'Ipotetu Image 2',
    },
  ];

  // Add the 'swiper-pointer' class if there is more than one slide
  const swiperClass = slides.length > 1 ? 'swiper-pointer' : '';

  return (
    <div className="work" id="ipotetu" ref={swiperContainerRef}>
      <div className={`c ${swiperClass}`}>
        <Swiper
          ref={ipotetuRef}
          loop={true}
          modules={[EffectFade]}
          effect="fade"
          spaceBetween={0}
          slidesPerView={1}
          onSwiper={(swiper) => (ipotetuRef.current = swiper)}
        >
          {slides.map((slide, index) => (
            <SwiperSlide key={index}>
              <img src={slide.src} alt={slide.alt} loading="lazy" />
              <div className="loader">
                <div className="loader--animation"></div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="ipotetu-img">
          <a
            href="https://ipotetu.com"
            title="Percent for art: Ipotêtu"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="./medias/ipotetu/ipotetu-link.svg"
              alt="Ipotetu.com"
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Ipotetu;
