import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { gsap } from 'gsap';
import { TypeShuffle } from '../c/typeShuffle';

const NotFound = () => {

    useEffect(() => {
        const ts = new TypeShuffle(document.querySelector('body'));

        const overlay = document.querySelector("#overlay");
        const loader = document.querySelector(".loader");
        const t = document.querySelector("#t");
        const b = document.querySelector("#b");
      
        const tl = gsap.timeline({
          onComplete: () => {
            if(overlay) {
              overlay.remove();
              if (ts) ts.trigger('fx2');
            }
          },
        });
      
        tl.to(loader, { opacity: 0, duration: 0.1})
        .to(t, { height: 0, duration: 0.45 }, 0)
        .to(b, { height: 0, duration: 0.45 }, 0);

    }, []);

    return (
      <div className="notFound">
        <div className="c">
        <h1>404</h1>
        <p>The page you are looking for does not exist :(</p>
        <p>
        <Link to="/">go to homepage</Link>
        </p>
        </div>
      </div>
    );
}

export default NotFound;