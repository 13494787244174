import React, { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { EffectFade } from 'swiper/modules';
import 'swiper/css/effect-fade';
import useSwiperLogic from '../../components/useSwiperLogic';
import { useDarkMode } from '../../context/DarkModeContext';

const SushiNouns = ({ isCurrent }) => {
  const sushiNounsRef = useRef(null);
  const swiperContainerRef = useRef(null);
  const { isDarkMode } = useDarkMode();

  // Apply Swiper logic
  useSwiperLogic('sushiNouns', sushiNounsRef, swiperContainerRef, isCurrent);

  // Define slides based on dark or light mode
  const slides = isDarkMode
    ? [
        {
          srcSet: './medias/07_sushi-nouns/sushi-nouns-poster-mobile-1.webp',
          media: '(max-width: 940px)',
          src: './medias/07_sushi-nouns/sushi-nouns-poster-1.webp',
          alt: 'Nouns DAO Japan voxel assets - Dark Mode',
        },
      ]
    : [
        {
          srcSet: './medias/07_sushi-nouns/sushi-nouns-poster-mobile-2.webp',
          media: '(max-width: 940px)',
          src: './medias/07_sushi-nouns/sushi-nouns-poster-2.webp',
          alt: 'Nouns DAO Japan voxel assets - Light Mode',
        },
      ];

  const swiperClass = slides.length > 1 ? 'swiper-pointer' : '';

  return (
    <div className="work border sushi-nouns" id="sushiNouns" ref={swiperContainerRef}>
      <div className={`c ${swiperClass}`}>
        <Swiper
          ref={sushiNounsRef}
          loop={true}
          modules={[EffectFade]}
          effect="fade"
          spaceBetween={0}
          slidesPerView={1}
          onSwiper={(swiper) => (sushiNounsRef.current = swiper)}
        >
          {slides.map((slide, index) => (
            <SwiperSlide key={index}>
              <picture>
                <source srcSet={slide.srcSet} media={slide.media} />
                <img src={slide.src} alt={slide.alt} loading="lazy" />
                <div className="loader">
                  <div className="loader--animation"></div>
                </div>
              </picture>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default SushiNouns;
