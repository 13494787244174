import { useEffect, useRef, useState } from 'react';
import { useDarkMode } from "../context/DarkModeContext"; // Import dark mode context

const useSwiperLogic = (id, swiperRef, swiperContainerRef, isCurrent, threshold = 0.65) => {
  const [lastScrollY, setLastScrollY] = useState(window.scrollY);
  const [hasSlid, setHasSlid] = useState(false); // Track if slide transition has occurred
  const { isDarkMode } = useDarkMode(); // Access dark mode state

  // Handle key press navigation (left and right arrow keys)
  useEffect(() => {
    if (!isCurrent) return;

    const handleKeyPress = (e) => {
      if (!swiperRef.current) return;

      if (e.keyCode === 37) { // left arrow
        swiperRef.current.slidePrev();
      } else if (e.keyCode === 39) { // right arrow
        swiperRef.current.slideNext();
      }
    };

    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [isCurrent]);

  // Handle scroll-triggered slide change
  useEffect(() => {
    if (hasSlid) return; // Exit if the slide has already transitioned

    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      const isScrollingDown = currentScrollY > lastScrollY;
      setLastScrollY(currentScrollY);

      if (!isScrollingDown || hasSlid) return;

      const swiperContainer = swiperContainerRef.current;
      const containerRect = swiperContainer.getBoundingClientRect();
      const isContainerVisible =
        containerRect.top < window.innerHeight && containerRect.bottom >= 0;

      if (!isContainerVisible) return;

      const slides = document.querySelectorAll(`#${id} .swiper-slide`);
      slides.forEach((slide) => {
        const slideRect = slide.getBoundingClientRect();
        const isThresholdMet =
          slideRect.top < 0 && slideRect.bottom <= window.innerHeight * threshold;

        if (isThresholdMet && swiperRef.current) {
          swiperRef.current.slideNext();
          setHasSlid(true); // Mark as transitioned
        }
      });
    };

    const scrollHandler = () => {
      window.requestAnimationFrame(handleScroll);
    };

    window.addEventListener('scroll', scrollHandler);

    return () => {
      window.removeEventListener('scroll', scrollHandler);
    };
  }, [lastScrollY, hasSlid]);

  // Force Swiper to update on dark mode toggle
  useEffect(() => {
    if (swiperRef.current) {
      swiperRef.current.update();
    }
  }, [isDarkMode]);

  // Handle click on slide to go to the next slide
  useEffect(() => {
    const slides = document.querySelectorAll(`#${id} .swiper-slide`);
    const clickHandler = () => {
      if (swiperRef.current) {
        swiperRef.current.slideNext();
      }
    };

    slides.forEach((slide) => {
      slide.addEventListener('click', clickHandler);
    });

    return () => {
      slides.forEach((slide) => {
        slide.removeEventListener('click', clickHandler);
      });
    };
  }, [id, isDarkMode]);

  return { isDarkMode };
};

export default useSwiperLogic;
